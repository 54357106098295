import { createAction, props } from '@ngrx/store';

import {
  PreviousReservationsDownloadRequest,
  PreviousReservationsTask,
  PreviousReservationsTasksRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Previous Reservations Tasks] Load Request',
  props<{
    request: PreviousReservationsTasksRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Previous Reservations Tasks] Load Success',
  props<{
    items: PreviousReservationsTask[];
  }>(),
);

export const loadFailure = createAction(
  '[Previous Reservations Tasks] Load Failure',
  props<{ error: any }>(),
);

export const downloadRequest = createAction(
  '[Previous Reservations Tasks] Download Request',
  props<{
    request: PreviousReservationsDownloadRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const downloadSuccess = createAction(
  '[Previous Reservations Tasks] Download Success',
);

export const downloadFailure = createAction(
  '[Previous Reservations Tasks] Download Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Previous Reservations Tasks] Reset State',
);
