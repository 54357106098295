import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';

import {
  PreviousReservationsRecordStatus,
  PreviousReservationsTaskRecord,
} from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'previousReservationsTaskRecords',
);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectByStatus = (status: PreviousReservationsRecordStatus) =>
  createSelector(
    selectAllItems,
    (records: PreviousReservationsTaskRecord[]) => {
      return records.filter((record) => record.status === status);
    },
  );

export const selectGroupByStatus = createSelector(
  selectAllItems,
  (records: PreviousReservationsTaskRecord[]) => {
    return groupBy(records, 'status');
  },
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
