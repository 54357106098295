import { createAction, props } from '@ngrx/store';

import {
  PreviousReservationsTask,
  PreviousReservationsTaskRecord,
  PreviousReservationsTaskRecordsRequest,
  PreviousReservationUpdateRecordsRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Previous Reservations Task Records] Load Request',
  props<{ request: PreviousReservationsTaskRecordsRequest }>(),
);

export const loadSuccess = createAction(
  '[Previous Reservations Task Records] Load Success',
  props<{
    items: PreviousReservationsTaskRecord[];
  }>(),
);

export const loadFailure = createAction(
  '[Previous Reservations Task Records] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Previous Reservations Task Records] Update Request',
  props<{
    request: PreviousReservationUpdateRecordsRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const updateSuccess = createAction(
  '[Previous Reservations Task Records] Update Success',
);

export const updateFailure = createAction(
  '[Previous Reservations Task Records] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Previous Reservations Task Records] Delete Request',
  props<{
    id: number;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const deleteSuccess = createAction(
  '[Previous Reservations Task Records] Delete Success',
  props<{ id: number }>(),
);

export const deleteFailure = createAction(
  '[Previous Reservations Task Records] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Previous Reservations Task Records] Reset State',
);
